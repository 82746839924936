import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk';

import { darklyClientId } from '@/config';
import { PRISM_FLAGS } from '@/config/flags';

// See https://launchdarkly.github.io/node-client-sdk/interfaces/LDOptions.html
const LDOptions = {
  sendEventsOnlyForVariation: true
};

const initializeLDProvider = async () => {
  return await asyncWithLDProvider({
    clientSideID: darklyClientId,
    options: LDOptions,
    reactOptions: {
      useCamelCaseFlagKeys: false
    },
    flags: {
      [PRISM_FLAGS.USE_LOGIN_API_FROM_BFF_USER]: false
    }
  });
};

export default initializeLDProvider;

import './index.scss';
import { DDRumConfig, RedirectUrl } from '@/config';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import { LoginService } from '@/services';
import './setupAxios';
import { initDatadogRum } from './datadogRum';
import initializeLDProvider from './utils/launchdarkly';

const render = async () => {
  let appInnerRenderer = <App />;

  try {
    const LDProvider = await initializeLDProvider();
    appInnerRenderer = (
      <LDProvider>
        <App />
      </LDProvider>
    );
  } catch (err) {
    console.error('Error initializing LaunchDarkly provider', err);
  }

  ReactDOM.render(
    <BrowserRouter>
      <React.StrictMode>{appInnerRenderer}</React.StrictMode>
    </BrowserRouter>,
    document.getElementById('root')
  );
};

const bootstrap = async () => {
  try {
    const res = await LoginService.isLoggedIn();
    const resData = res.data;
    const isLoggedIn = resData?.is_logged_in || '0';
    const redirectUrl = resData?.redirect || '';

    if (isLoggedIn === '1') {
      window.location.href =
        redirectUrl != '' ? redirectUrl : RedirectUrl.reporting;
    } else {
      render();
    }
  } catch (err) {
    render();
  }
};

if (process.env.NODE_ENV === 'production') {
  void bootstrap();
} else {
  render();
}

DDRumConfig.isEnabled && initDatadogRum();

import axios from 'axios';

const isLoggedIn = () => {
  return axios.get(`${process.env.API_INTERNAL_BASEURL}/is_logged_in/true`);
};

const login = (username: string, password: string, code?: string) => {
  return axios.post(`${process.env.API_INTERNAL_BASEURL}/login`, {
    username,
    password,
    ...(code && { code })
  });
};

const loginByBffUser = (username: string, password: string, code?: string) => {
  const loginUrl = `${process.env.BFF_USER_API}/login/v2`;

  return axios.post(loginUrl, {
    username,
    password,
    ...(code && { code })
  });
};

const forgotPassword = (username: string) => {
  return axios.post(`${process.env.API_INTERNAL_BASEURL}/forgot_password`, {
    username: username
  });
};

const checkIfUserIsFederated = (email: string) => {
  return axios.get(`${process.env.CW_AUTH_BASE_URL}/federation`, {params: {email}, withCredentials: true});
}

export default {
  isLoggedIn,
  login,
  loginByBffUser,
  forgotPassword,
  checkIfUserIsFederated
};
